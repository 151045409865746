.print {
    border: 1px solid;
    padding: 3px !important;
    font-size: 9px;
}

html,
body {
    width: auto;
    height: auto;
}