.ant-card-head {
    background: #EAE1C9;
    color: #252524;
  }
  
  .ant-card-body {
    background: linear-gradient(to right, #cfb694, #D1913C);
  }

  th, td {
    padding: 5px !important;
  }

  .form-item-ts{
    margin-bottom: 0px !important;
  }

  .ant-empty{
    position: absolute;
    left: 0;
    right: 0;
  }

  .ant-radio-wrapper{
    margin-right: 0px;
  }

  
  #theadp{
    font-size: 12px !important;
  }

  .table-sticky{
    position: -webkit-sticky; /* Safari */
    position: sticky !important;
    top: 0 !important;
    z-index: 200;
  }

  .table-responsive{
    height: 400px !important;
  }

  .table-bordered>:not(caption)>* {
    border-width: 0px 0px 0px 0px !important;
}