.list-style {
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 9px;
  /* border-radius: 12px; */
  margin: auto;
  cursor: pointer !important;
  margin-bottom: 20px;
  background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/fb9490be.png?ect=4g);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.duplicate-entry {
  border-color: #ff4d4f;
  border: 0.5;
  border-width: 0.5;
  border-style: dotted;
}

.title {
  color: #2a2b3d;
}

.ant-ribbon {
  top: 65px !important;
}

.delete-filter {
  margin-top: 22px;
  color: var(--GN500, #ffffff) !important;
  background-color: var(--GN50, #ff4d4f) !important;
  border: 1px solid var(--GN400, #ff4d4f) !important;
}

@media only screen and (max-width: 600px) {
  .delete-filter {
    margin-top: 18px;
  }
}

.ant-collapse {
  border-radius: 0px !important;
  background: rgba(255, 255, 255, 0.8) !important;
}
