.even-column {
    background-color: #f0f8ff;
    /* Light blue for even columns */
}

.odd-column {
    background-color: #ffe4e1;
    /* Light pink for odd columns */
}

.rbc-current-time-indicator {
    display: none;
}

.rbc-row {
    display: none;
}

.custom-header {
    font-weight: bold;
    text-align: center;
    padding: 5px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.rbc-event-label {
    display: none;
}

.rbc-day-slot .rbc-event-content {
    align-content: center;
}

.rbc-toolbar {
    margin-bottom: -14px;
}

.rbc-time-content {
    margin-top: 13px !important;
    border: 0px !important;
}

.rbc-time-view {
    border: 0px !important;
}

.rbc-time-header {
    display: block;
}

.rbc-toolbar .rbc-btn-group {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    align-self: flex-start;
}

.rbc-toolbar .rbc-toolbar-label {
    text-align: start !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    border: 1px solid rgba(37, 41, 44, 0.5);
    background-image: linear-gradient(to left, #FF9900, #FF9900);
}

.event-background-approved {
    background-image: linear-gradient(to left, #1890FF, #1890FF) !important;
}

.ant-alert span {
    align-self: center !important;
}

.red-time-slot {
    background-color: #ff4d50a7 !important;
    color: white;
}

.rbc-time-gutter {
    background-color: #188fff59 !important;
}

.rbc-day-slot {
    background-color: #188fff59 !important;
}

.watermark {
    position: relative;
}

.watermark::after {
    content: 'Odd Even Restriction';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #E27B8B;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    pointer-events: none;
    padding: 4px;
    border-radius: 5px;
}

.currentOdd svg {
    margin-top: -6px;
    margin-right: 5px;
}

.rbc-row-content {
    background: #FFFFFF;
}

/* file styles.css atau dalam tag <style> di HTML */
.rbc-btn-group button {
    margin-right: 8px;
    /* background-color: rgb(224, 113, 15); */
    color: var(--GN500, #00AA5B) !important;
    background-color: var(--GN50, #ECFEF4) !important;
    border: 1px solid var(--GN400, #20CE7D) !important;
    color: #FFF;
    border-color: #d9d9d9;
    background: #fff;
    border-style: dashed;
    margin-bottom: 5px;
}

/* Hapus margin dari tombol terakhir jika tidak diinginkan */
.rbc-btn-group button:last-child {
    margin-right: 0;
}