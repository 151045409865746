* {
    font-family: 'Poppins', sans-serif;
}


body {
    margin: 0;
    padding: 0;
    background: #f9f9f9 !important;
    /* min-width: 471px;
    max-width: 787px; */
}

.home_back {
    /* background-color: #E9E1CA; */
    /* background-image: linear-gradient(to right, #ffffff, #ffffff); */
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/149df038.svg);
}

.footer_background {
    /* background-image: linear-gradient(to right, #ffffff, #ffffff); */
    background-image: linear-gradient(to right, #F9F9F9, #F9F9F9);
}

.ant-statistic-title {
    font-size: 14px;
}

.ant-statistic-content {
    font-size: 16px;
}

.item-menu {
    width: 65.69px;
    height: 65.69px;
    background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/b33421a4.svg),
        linear-gradient(195deg, #daa110 0%, #f7ce68 100%);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
    border-radius: 30%;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.logo-menu {
    margin: 13px;
}

.title-item-menu {
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    color: var(--NN600, #6D7588);
}

/* Custom antd */

.ant-alert {
    border-radius: 25px;
}

button {
    border-radius: 8px !important;
}


/* file styles.css */
.ant-btn,
.ant-input,
.ant-select,
.ant-card,
.ant-modal,
.ant-table,
.ant-form-item,
.ant-checkbox,
.ant-radio,
.ant-tabs,
.ant-dropdown-menu,
.ant-select-dropdown,
.dropdownMenu,
.ant-tag {
    border-radius: 8px !important;
}

.ant-collapse,
.ant-collapse-item {
    border-radius: 9px !important;
    border: 0px;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 8px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/b33421a4.svg),
        linear-gradient(90deg, rgb(0, 114, 72) 0%, rgb(9, 171, 103) 100%) !important;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto;
    border-color: #FFFFFF !important;
    border-radius: 5px !important;
    border: none;
    color: var(--NN0, #FFFFFF);
}

.ant-btn-primary {
    background-image: url(https://images.tokopedia.net/img/cache/250/attachment/2019/10/23/40768394/40768394_7c9ad641-8a48-4b63-a4cc-cae37bbcaae1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
}

.ant-collapse-content {
    border: 0px;
}

.ant-collapse>.ant-collapse-item {
    background-color: #FFFFFF !important;
}

.ant-descriptions-view {
    border-radius: 8px;
}

.ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    border: 0px;
}

.ant-ribbon {
    border-radius: 8px;
}

/* Resize the navbar items */
.navbar-nav .nav-item .nav-link {
    background: #FFFFFF;
    padding: 0.5rem 0;
    /* Adjust padding to your needs */
    font-size: 0.8rem;
    /* Adjust font size to make text smaller */
}

.navbar-nav .nav-item .nav-link svg {
    width: 1.2em;
    /* Adjust the icon size */
    height: 1.2em;
    /* Adjust the icon size */
}

.navbar-nav .nav-item .nav-link div {
    font-size: 0.6rem;
    /* Adjust font size for labels */
}

/* Optional: Adjust badge size if necessary */
.ant-badge {
    font-size: 0.6rem;
    /* Adjust badge font size */
    padding: 0.1em;
    /* Adjust badge padding */
}

.navbar {
    transition: transform 0.2s ease-in-out;
}

.ant-alert {
    background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/b3fc43bf.svg);
    border: 2px solid var(--NN50, #F0F3F7);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.navbar.hide {
    transform: translateY(100%);
}