.list-style {
    height: 100px;
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 9px;
    margin: auto;
    cursor: pointer !important;
    margin-bottom: 20px;
    background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/fb9490be.png?ect=4g);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.ant-result {
    padding: 20px 6px !important;
}

.ant-result-icon>.anticon {
    font-size: 115px !important;
}

.title {
    color: #2A2B3D;
}

.ant-ribbon {
    top: 65px !important;
}

.ant-collapse {
    border-radius: 0px !important;
    background: rgba(255, 255, 255, 0.8) !important;
}