.list-style {
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 9px;
  /* border-radius: 12px; */
  margin: auto;
  cursor: pointer !important;
  margin-bottom: 20px;
  background-image: url(https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/fb9490be.png?ect=4g);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.title {
  color: #2a2b3d;
}

.ant-ribbon {
  top: 65px !important;
}

.ant-collapse {
  border-radius: 0px !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

.btn-color-gradient {
  color: #ffffff;
  background: rgb(2, 132, 70);
  background: linear-gradient(
    34deg,
    rgba(2, 132, 70, 1) 0%,
    rgba(64, 178, 74, 1) 100%
  );
  transition: background 0.3s ease-in-out;
  border: none !important;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.btn-color-gradient:hover {
  color: #ffffff;
  background: rgb(64, 178, 74) !important;
  background: linear-gradient(
    34deg,
    rgba(64, 178, 74, 1) 0%,
    rgba(2, 132, 70, 1) 100%
  ) !important;
  border: none !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}
