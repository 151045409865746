.ant-card-head {
    background: #EAE1C9;
    color: #252524;
  }
  
  .ant-card-body {
    background: linear-gradient(to right, #cfb694, #D1913C);
  }
  
  .label_attachment .ant-form-item-required{
    height: 30px !important;
  }
  
  th,
  td {
    padding: 5px !important;
  }
  
  .form-item-ts {
    margin-bottom: 0px !important;
  }
  
  .ant-empty {
    position: absolute;
    left: 0;
    right: 0;
  }
  
  .ant-radio-wrapper {
    margin-right: 0px;
  }
  
  .scroller {
    overflow: auto;
  }
  
  #theadp {
    font-size: 12px !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
  }