.btn-menu{
    height: 50px;
    width: 100%;
    background-color: #C0D3F9;
    border-radius: 12px;
}

.active{
    background: linear-gradient(180deg, #1866F1 0%, #1866F1 100%);
    color: #FFFFFF !important;
    border-radius: 9px;
    height: 50px;
    border: 3px solid #C0D3F9;
}

.card-header{
    background-color:#ffffff;
    border:0;
}

.btn-tab{
    cursor: pointer !important;
}

