.ant-page-header-heading-left {
    flex-wrap: wrap;
}

.ant-page-header-heading-title {
    font-size: 14px !important;
    flex: 1
}

.navbar>.container-fluid {
    flex-wrap: nowrap;
}

.navbar-light {
    background-color: rgba(255, 255, 255, 0.8) !important;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.03);
    padding: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ant-page-header-ghost {
    flex: 1;
}

.ant-card-bordered {
    border: 0px solid #f0f0f0;
}

.uinfo {
    font-size: 7.5px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-right: 20px;
}